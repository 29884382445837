export const menuItems = [
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        badge: {
            variant: "success",
            text: "menuitems.dashboard.badge"
        },
        link: '/'
    },
    {
        id: 3,
        label: 'menuitems.icp.text',
        icon: 'fas fa-globe-americas',
        subItems: [
            {
                id: 301,
                label: 'menuitems.icp.micoapp.text',
                link: '/icp/micoapp'
            }
        ]
    },
    {
        id: 18,
        isLayout: true
    }
]