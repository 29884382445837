<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";

export default {
  components: { Vertical },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed
  },
  mounted(){
      var that=this
      var userinfo = JSON.parse(localStorage.getItem('user'));
      
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      if(flag){
           that.$root.ismobile= true
      }
      
  },
  methods: {}
};
</script>

<template>
  <div>
    <vertical :layout="layoutType">
      <slot />
    </vertical>
  </div>
</template>
